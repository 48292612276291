import { Button, Card, CardActions, CardContent } from '@material-ui/core'
import React from 'react'
import { ArrowRight } from 'react-feather'

type Props = {}

export default function Overview({}: Props) {
  return (
    <div className="flex gap-5">
      <Card
        // ref={setNodeRef1}
        style={{
          background: 'linear-gradient(to right, hsla(180, 100%, 25%, 1), hsla(180, 100%, 30%, 1)',
          display: 'flex',
        }}
        className="flex-1 flex-col justify-between"
      >
        <CardContent
          className="flex justify-between"
          style={{
            paddingBottom: 0,
          }}
        >
          <h2 className="text-2xl m-0 font-medium">Curriculums</h2>
          <p className="text-2xl font-bold">47</p>
        </CardContent>
        <CardActions>
          <Button endIcon={<ArrowRight />}>View</Button>
        </CardActions>
      </Card>

      <Card
        style={{
          background: 'linear-gradient(to right, hsla(180, 100%, 30%, 1), hsla(180, 100%, 40%, 1))',
          display: 'flex',
        }}
        className="flex-1 flex-col justify-between"
      >
        <CardContent
          className="flex justify-between"
          style={{
            paddingBottom: 0,
          }}
        >
          <h2 className="text-2xl m-0 font-medium">Coaches</h2>
          <p className="text-2xl font-bold">23</p>
        </CardContent>
        <CardActions>
          <Button endIcon={<ArrowRight />}>View</Button>
        </CardActions>
      </Card>

      <Card
        style={{
          background: 'linear-gradient(to right, hsla(180, 100%, 40%, 1), hsla(180, 100%, 50%, 1))',
          display: 'flex',
        }}
        className="flex-1 flex-col justify-between"
      >
        <CardContent
          className="flex justify-between"
          style={{
            paddingBottom: 0,
          }}
        >
          <h2 className="text-2xl m-0 font-medium">Employees</h2>
          <p className="text-2xl font-bold">15</p>
        </CardContent>
        <CardActions>
          <Button endIcon={<ArrowRight />}>View</Button>
        </CardActions>
      </Card>

      <Card
        style={{
          background: 'linear-gradient(to right, hsla(180, 100%, 50%, 1), hsla(180, 100%, 60%, 1))',
          display: 'flex',
        }}
        className="flex-1 flex-col justify-between"
      >
        <CardContent
          className="flex justify-between"
          style={{
            paddingBottom: 0,
          }}
        >
          <div>
            <h2 className="text-2xl m-0 font-medium">Budget</h2>
            <p>Remaining Company Budget</p>
          </div>
          <p className="text-xl font-bold">$15,000</p>
        </CardContent>
        <CardActions>
          <Button endIcon={<ArrowRight />}>View</Button>
        </CardActions>
      </Card>
    </div>
  )
}
