import { useEffect, useState } from 'react'
import { CellProps } from 'react-table'
import { getAnActivity } from './HREmployeeViewPage.helper'
import { Activity } from './type'
import { CircularProgress, LinearProgress } from '@material-ui/core'

type Props = {} & CellProps<any>

export default function ActivityProgress({ row }: Props) {
  const [data, setData] = useState<Activity | null>(null)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const activityId = row.original.coachActivityId
    setIsLoading(true)

    getAnActivity(activityId)
      .then((data) => {
        setData(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const sessionCount = data?.sessions.length || 0
  const completedSessionCount = data?.sessions?.filter((x) => x.status === 'completed').length || 0
  const percentage = (completedSessionCount / sessionCount) * 100

  if (isLoading) {
    // return <div className=" text-gray-500">Loading</div>
    return <CircularProgress size={20} />
  }

  return (
    <div>
      <LinearProgress variant="determinate" value={percentage} />
      <p className="text-sm mt-1 text-gray-700">
        {completedSessionCount} completed out of {sessionCount}
      </p>
    </div>
  )
}
