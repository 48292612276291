import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { fetchCurriculum, fetchCurriculumAttendeeBudget } from './EmployeeEnrollPage.helper'
import { CircularProgress } from '@material-ui/core'
import css from './EmployeeEnrollPage.module.scss'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import ReactHtmlParser from 'react-html-parser'
import { decode } from 'html-entities'
import { enrollRequest } from '../EmployeeSelectListingPage/EmployeeSelectListingPage.helper'
import { useConfirm } from 'material-ui-confirm'
import { useAlert } from 'react-alert'
import { fetchMyBudgetData } from '../EmployeeCompanyPage/EmployeeCompanyPage.helper'
import { vivekaUrl } from '../../../env-config'

interface PropsI
  extends MapStatePropsI,
    MapDispatchPropsI,
    RouteComponentProps<{ curriculumId: string }> {}

const EmployeeEnrollPage: React.FC<PropsI> = (props) => {
  const { match, history } = props

  const [curriculum, setCurriculum] = useState<any>()
  const [budget, setBudget] = useState<any>()

  const [budgetData, setBudgetData] = useState<any>()

  const confirm = useConfirm()
  const alert = useAlert()

  useEffect(() => {
    fetchCurriculum(match.params.curriculumId).then((res) => setCurriculum(res.data))
    fetchCurriculumAttendeeBudget(match.params.curriculumId).then((res) => setBudget(res.data))
    fetchMyBudgetData().then((res) => setBudgetData(res.data))
  }, [match.params.curriculumId])

  // console.log({ budget })

  const enrollClickHandler = (listingId: any) => {
    confirm().then(() => {
      enrollRequest(match.params.curriculumId, listingId)
        .then((res) => {
          alert.success(res.message)
          history.push('/employee/company/my-curriculums')
        })
        .catch((err) => {
          alert.error(err)
        })
    })
  }

  return (
    <MainDashboardWrapper title="Enroll In a Curriculum" page="client-company">
      {!curriculum && <CircularProgress size={25} />}
      <div className={css.base}>
        <div className={css.head}>
          <div>
            <div className={css.budget_details}>
              <div className={css.budget_card}>
                <p>Remaining Discretionary Company Budget</p>
                <p>${(budgetData?.company?.discretionaryBudget || 0).toLocaleString()}</p>
              </div>
              <div className={css.budget_card}>
                <p>Remaining Required Company Budget</p>
                <p>${(budgetData?.company?.requiredBudget || 0).toLocaleString()}</p>
              </div>
              <div className={css.budget_card}>
                <p>Remaining Curriculum Budget</p>
                <p>${curriculum?.currentBudget?.toLocaleString()}</p>
              </div>
              <div>
                <p>Available Budget / Utilized</p>
                <p>
                  ${(budget?.currentBudget || 0).toLocaleString()} / $
                  {(budget?.utilized || 0).toLocaleString()}
                </p>
              </div>
            </div>
            <p className={css.title}>{curriculum?.name}</p>
            <div className={css.categories}>
              {curriculum?.categories?.map((category: any, index: number) => {
                return (
                  <div key={index} className={css.category}>
                    {category.name}
                  </div>
                )
              })}
            </div>
            <p style={{ marginBottom: 10 }}>{curriculum?.type?.toUpperCase()}</p>
          </div>
        </div>
        <div className={css.description}>{curriculum?.description}</div>
        <br />
        <div className={css.listings}>
          {curriculum?.listings?.map((item: any, index: number) => {
            console.log(item)
            return (
              <div key={index} className={css.listing_card}>
                <img className={css.listing_image} alt="listing" src={item?.images[0] || ''} />
                <div className={css.listing_details}>
                  <p>{item.name}</p>
                  <p>{item.owner.displayName}</p>
                  <div className={css.listing_description}>
                    {ReactHtmlParser(decode(item.description))}
                  </div>
                  <div className={css.listing_categories}>
                    {item?.typeOfService?.map((t: any, i: number) => {
                      return <div key={i}>{t}</div>
                    })}
                  </div>
                </div>
                <div className={css.listing_action}>
                  <p>
                    ${item.price.toLocaleString()} / {item.type}
                  </p>
                  {/*<Link to={`${window.location.pathname}/${item.id}`} className={css.button}>*/}
                  {/*  Enroll*/}
                  {/*</Link>  */}
                  <div className={css.button} onClick={() => enrollClickHandler(item.id)}>
                    Enroll
                  </div>
                  <a className={css.button2} href={`${vivekaUrl}/l/${item.uuid}`} target="_blank">
                    View
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </MainDashboardWrapper>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeEnrollPage))
