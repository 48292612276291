import React from 'react'
import css from './CurriculumEmployeeBulkBudgetAllocationPopup.module.scss'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { Button, CircularProgress, MenuItem, Select } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import NumberFormatInputField from '../NumberFormatInputField/NumberFormatInputField'
import { FormikHelpers } from 'formik/dist/types'
import * as yup from 'yup'
import SmallRoundColoseButton from '../SmallRoundCloseButton/SmallRoundCloseButton'
import { useAlert } from 'react-alert'
import { useConfirm } from 'material-ui-confirm'
import { allocateCurriculumEmployeeBudget } from '../../containers/HR/HRCurriculumViewPage/HRCurriculumViewPage.helper'

interface FormFieldsI {
  type: string
  remark: string
  amount: number | string
}

interface PropsI extends RouteComponentProps {
  trigger: JSX.Element | ((isOpen: boolean) => JSX.Element)
  onSuccessCallback: () => void
  curriculumId: string | number
}

const CurriculumEmployeeBulkBudgetAllocationPopup: React.FC<PropsI> = (props) => {
  const { trigger, onSuccessCallback, curriculumId } = props

  const alert = useAlert()
  const confirm = useConfirm()

  const onSubmit = (
    values: FormFieldsI,
    formikHelpers: FormikHelpers<FormFieldsI>,
    close: () => void
  ) => {
    confirm().then(() => {
      allocateCurriculumEmployeeBudget(values, curriculumId)
        .then(() => {
          alert.success('Success')
        })
        .catch(() => {
          alert.error('Error')
        })
        .finally(() => {
          onSuccessCallback()
          close()
        })
    })

    // formikHelpers.setSubmitting(false)
  }

  const validationSchema = yup.object({
    type: yup.string().label('Type').required(),
    remark: yup.string().label('Remark').required(),
    amount: yup.number().label('Amount').required(),
  })

  const initialValues: FormFieldsI = { type: 'increase', remark: '', amount: '' }

  return (
    <Popup
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      // onOpen={() => setItems((window as any).selectedRows)}
    >
      {(close: any) => (
        <div className={css.base}>
          <SmallRoundColoseButton onClick={close} className={css.close_button} />

          <h2>Budget Allocation</h2>
          <p>Bulk budget allocation for employees in the curriculum</p>
          <Formik
            initialValues={initialValues}
            onSubmit={(...args) => onSubmit(...args, close)}
            validationSchema={validationSchema}
          >
            {(formikProps: FormikProps<FormFieldsI>) => {
              return (
                <Form className={css.form}>
                  <Field name="type">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Select
                          variant="outlined"
                          value={field.value}
                          onChange={(event) => {
                            formikProps.setFieldValue(field.name, event.target.value)
                          }}
                        >
                          <MenuItem value="increase">Increase</MenuItem>
                          <MenuItem value="decrease">Decrease</MenuItem>
                        </Select>
                      )
                    }}
                  </Field>

                  <Field
                    className={css.input_field}
                    component={TextField}
                    name="amount"
                    variant="outlined"
                    label="Amount"
                    color="primary"
                    required
                    InputProps={{
                      inputComponent: NumberFormatInputField,
                    }}
                  />

                  <Field
                    className={css.input_field}
                    component={TextField}
                    required
                    name="remark"
                    variant="outlined"
                    label="Remark"
                    color="primary"
                  />

                  <Button
                    className={css.submit_button}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disableElevation
                    disabled={
                      formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty
                    }
                  >
                    {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Allocate'}
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      )}
    </Popup>
  )
}

export default withRouter(CurriculumEmployeeBulkBudgetAllocationPopup)
