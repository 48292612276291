import { RouteComponentProps, useParams, withRouter } from 'react-router-dom'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { useEffect, useState } from 'react'
import { getASurvey, getAnswers } from './helpers'
import { Survey } from '../../../types/survey'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'

interface PropsI extends RouteComponentProps {}

const HRSurveyViewAnswerPage: React.FC<PropsI> = (props) => {
  const { history } = props
  const { answerId, questionId, surveyId } = useParams<{
    surveyId: string
    questionId: string
    answerId: string
  }>()

  const [answers, setAnswers] = useState<any[]>([])
  // console.log('🚀 ~ answers:', answers)

  const question = answers.length > 0 && answers[0]?.surveyQuestion
  const answer = question?.options?.find((x: any) => x.value === answerId)
  // console.log('🚀 ~ question:', question)

  useEffect(() => {
    getAnswers(questionId, answerId).then((data) => {
      setAnswers(data)
    })
  }, [questionId, answerId])

  return (
    <MainDashboardWrapper title="Survey View Answer" page="corp-survey">
      {question ? (
        <>
          <h1 className="m-0">{question?.name}?</h1>
          <p className="font-bold">Answer: {answer?.label}</p>
          <div className="flex flex-wrap gap-5 mt-5">
            {answers.map((ans: any) => {
              return (
                <div
                  key={ans.id}
                  className="rounded-md p-5 min-w-48"
                  style={{
                    border: '1px solid #c6c6c6',
                  }}
                >
                  <div className="font-semibold">{ans?.answeredUser?.displayName}</div>
                  <div className="text-gray-700 mt-2">
                    Listing: <span className="font-semibold"> {ans?.activity?.listing?.name}</span>
                  </div>
                  <div className="text-gray-700">
                    Program:<span className="font-semibold"> {ans?.activity?.program?.name}</span>
                  </div>
                  <div className="text-gray-700">
                    Session: <span className="font-semibold"> {ans?.session.name}</span>
                  </div>
                  {/* <div>Answer: {question?.options?.find((x: any) => x.value === ans.answer).label}</div> */}
                  <div className="text-gray-700">
                    Answered At:
                    <span className="font-semibold">
                      {' '}
                      {moment(ans?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        </>
      ) : (
        'No data to show'
      )}
    </MainDashboardWrapper>
  )
}

export default withRouter(HRSurveyViewAnswerPage)
