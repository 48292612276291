import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import css from './ListingCard.module.css'
import classNames from 'classnames'
// import Img1 from './images/executive1.png'
// import Img2 from './images/executive2.png'
// import Img3 from './images/executive3.png'
// import Img4 from './images/executive4.png'
// import Img5 from './images/executive5.png'
// import Img6 from './images/business1.png'
// import Img7 from './images/business2.png'
// import Img8 from './images/business3.png'

function CurrCard(props: any) {
  const { title, type, description, remaining, url, categories } = props
  // let id = 'asd';
  // const images = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8]

  // console.log(categories)

  let randomMax = 3

  let firstCategory = String(categories[0]?.name).toLowerCase()
  if (firstCategory === 'intuitive healing') {
    firstCategory = 'intuitive-healing'
  }

  if (firstCategory === 'executive') {
    randomMax = 5
  }
  const randomNumber = Math.floor(Math.random() * randomMax) + 1
  const imageSrc = `/assets/categories/${firstCategory}/${firstCategory}${randomNumber}.png`

  // const randIndex = Math.floor(Math.random() * 5) + 1
  // console.log(randIndex)

  return (
    <div className={classNames(css.base)}>
      <div className={css.imageBase}>
        <img alt="Listing" className={css.img} src={imageSrc} />
      </div>

      <div className={css.contentBase}>
        <div className={css.nameBlock}>
          <div className={css.name}>{title}</div>

          {/*<p className={css.from}>{title}</p>*/}
        </div>

        <div style={{ textDecoration: 'none', color: 'inherit' }}>
          <div className={css.author}>
            <p>{type}</p>
          </div>
        </div>

        {/*<div className={css.expertSections}>*/}
        {/*  <p>sdfsdf</p>*/}
        {/*</div>*/}

        <div className={css.description}>{description}</div>

        <div className={css.serviceList}>
          {categories?.map((x: any) => {
            return (
              <div
                style={{
                  background: '#d9ffff',
                  borderRadius: '50px',
                  padding: '5px 10px',
                }}
              >
                {x.name}
              </div>
            )
          })}
        </div>
      </div>

      <div className={css.actionBase}>
        <p className={css.price}>${remaining ? remaining?.toLocaleString() : 0} Remaining</p>
        <Link to={url} className={css.button}>
          See the offerings
        </Link>
      </div>
    </div>
  )
}

export default withRouter(CurrCard)
