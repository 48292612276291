import React from 'react'
import HRCurriculumPage from './containers/HR/HRCurriculumPage/HRCurriculumPage'
import { Redirect } from 'react-router-dom'
import EditHRCurriculumPage from './containers/HR/EditHRCurriculumPage/EditHRCurriculumPage'
import HREmployeesPage from './containers/HR/HREmployeesPage/HREmployeesPage'
import HRBillingPage from './containers/HR/HRBillingPage/HRBillingPage'
import EmployeeCompanyPage from './containers/Employee/EmployeeCompanyPage/EmployeeCompanyPage'
import AddHREmployeesPage from './containers/HR/AddHREmployeesPage/AddHREmployeesPage'
import EmployeeEnrollPage from './containers/Employee/EmployeeEnrollPage/EmployeeEnrollPage'
import EmployeeSelectListingPage from './containers/Employee/EmployeeSelectListingPage/EmployeeSelectListingPage'
import NoPermissionPage from './containers/NoPermissionPage/NoPermissionPage'
import MyCompaniesPage from './containers/MyCompaniesPage/MyCompaniesPage'
// import CompanyCreatePage from './containers/CompanyCreatePage/CompanyCreatePage'
import InvitationVerifyPage from './containers/InvitationVerifyPage/InvitationVerifyPage'
import AuthPage from './containers/AuthPage/AuthPage'
import HRCoachesPage from './containers/HR/HRCoachesPage/HRCoachesPage'
import HRCoachAddPage from './containers/HR/HRCoachAddPage/HRCoachAddPage'
import HRCurriculumEditListings from './containers/HR/HRCurriculumEditListings/HRCurriculumEditListings'
import HRDashboardPage from './containers/HR/HRDashboardPage/HRDashboardPage'
import HRSettingPage from './containers/HR/HRSettingPage/HRSettingPage'
import MyOrders from './containers/Coach/MyOrders/MyOrders'
import MainDashboardWrapper from './components/MainDashboardWrapper/MainDashboardWrapper'
import HREmployeeViewPage from './containers/HR/HREmployeeViewPage/HREmployeeViewPage'
import HRCurriculumEditEmployees from './containers/HR/HRCurriculumEditEmployees/HRCurriculumEditEmployees'
import HRCurriculumViewPage from './containers/HR/HRCurriculumViewPage/HRCurriculumViewPage'
import HRCurriculumEmployeeViewPage from './containers/HR/HRCurriculumEmployeeViewPage/HRCurriculumEmployeeViewPage'
import AutoAuthLoginPage from './containers/AutoAuthPage/AutoAuthLoginPage'
import AutoAuthLogoutPage from './containers/AutoAuthPage/AutoAuthLogoutPage'
import InvoiceReport from './containers/HR/HRBillingPage/BillingCompanyInvoiceView/InvoiceReport'
import HRSurveyPage from './containers/HR/HRSurvey/HRSurveyPage'
import HRSurveyEditPage from './containers/HR/HRSurvey/HRSurveyEditPage'
import HRSurveyQuestionBuilderPage from './containers/HR/HRSurvey/HRSurveyQuestionBuilderPage'
import HRSurveyViewPage from './containers/HR/HRSurvey/HRSurveyViewPage'
import HRSurveyViewAnswerPage from './containers/HR/HRSurvey/HRSurveyViewAnswerPage'

const ROLES = {
  EMPLOYEE: 'employee',
  HR: 'hr',
  ADMIN: 'admin',
  COACH: 'coach',
  NO_COMPANY: 'no-company',
}

export const routes: RouteI[] = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/hr" />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr',
    exact: true,
    component: () => <Redirect to="/hr/curriculum" />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/dashboard',
    exact: true,
    component: () => <Redirect to="/hr/dashboard/monthly" />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/dashboard/:tab',
    exact: true,
    component: (props: any) => <HRDashboardPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/settings',
    exact: true,
    component: (props: any) => <HRSettingPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/survey',
    exact: true,
    component: (props: any) => <HRSurveyPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/survey/edit/:id',
    exact: true,
    component: (props: any) => <HRSurveyEditPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/survey/view/:id',
    exact: true,
    component: (props: any) => <HRSurveyViewPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/survey/view/:surveyId/question/:questionId/answer/:answerId',
    exact: true,
    component: (props: any) => <HRSurveyViewAnswerPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/survey/builder/:id',
    exact: true,
    component: (props: any) => <HRSurveyQuestionBuilderPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/curriculum',
    exact: true,
    component: (props: any) => <HRCurriculumPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/curriculum/details/:id',
    exact: true,
    component: (props: any) => <HRCurriculumViewPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/curriculum/details/:id/:empId/:tab',
    exact: true,
    component: (props: any) => <HRCurriculumEmployeeViewPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },

  {
    path: '/hr/curriculum/edit',
    exact: true,
    component: (props: any) => <EditHRCurriculumPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/curriculum/edit/:id/listings',
    exact: true,
    component: (props: any) => <HRCurriculumEditListings {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/curriculum/edit/employees/:id',
    exact: true,
    component: (props: any) => <HRCurriculumEditEmployees {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/curriculum/edit/:id',
    exact: true,
    component: (props: any) => <EditHRCurriculumPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/employee',
    exact: true,
    component: (props: any) => <HREmployeesPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/employee/add',
    exact: true,
    component: (props: any) => <AddHREmployeesPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/employee/view/:id',
    exact: true,
    component: (props: any) => <HREmployeeViewPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/report/invoice/:id',
    exact: true,
    component: (props: any) => <InvoiceReport {...props} />,
    private: true,
    roles: [ROLES.HR],
  },

  {
    path: '/hr/coach',
    exact: true,
    component: (props: any) => <HRCoachesPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },
  {
    path: '/hr/coach/add',
    exact: true,
    component: (props: any) => <HRCoachAddPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },

  {
    path: '/hr/billing/:tab',
    exact: true,
    component: (props: any) => <HRBillingPage {...props} />,
    private: true,
    roles: [ROLES.HR],
  },

  {
    path: '/employee',
    exact: true,
    component: () => <Redirect to="/employee/company" />,
    private: true,
    roles: [ROLES.EMPLOYEE],
  },
  // {
  //   path: '/employee/my-activity',
  //   exact: true,
  //   component: (props: any) => <EmployeeMyActivities {...props} />,
  //   private: true,
  //   roles: [ROLES.EMPLOYEE],
  // },
  {
    path: '/employee/company',
    exact: true,
    component: (props: any) => <Redirect to="/employee/company/my-curriculums" />,
    private: true,
    roles: [ROLES.EMPLOYEE],
  },
  {
    path: '/employee/company/:tab',
    exact: true,
    component: (props: any) => <EmployeeCompanyPage {...props} />,
    private: true,
    roles: [ROLES.EMPLOYEE],
  },
  {
    path: '/employee/company/enroll/:curriculumId',
    exact: true,
    component: (props: any) => <EmployeeEnrollPage {...props} />,
    private: true,
    roles: [ROLES.EMPLOYEE],
  },
  {
    path: '/employee/company/enroll/:curriculumId/:listingId',
    exact: true,
    component: (props: any) => <EmployeeSelectListingPage {...props} />,
    private: true,
    roles: [ROLES.EMPLOYEE],
  },

  {
    path: '/coach',
    exact: true,
    component: () => <Redirect to="/coach/my-orders" />,
    private: true,
    roles: [ROLES.COACH],
  },
  {
    path: '/coach/my-orders',
    exact: true,
    component: (props: any) => <MyOrders {...props} />,
    private: true,
    roles: [ROLES.COACH],
  },
  {
    path: '/auth',
    exact: true,
    component: (props: any) => <Redirect to="/auth/login" />,
  },
  {
    path: '/auth/auto-auth/:access_token/:refresh_token',
    component: (props: any) => <AutoAuthLoginPage {...props} />,
    exact: true,
  },
  {
    path: '/auth/auto-auth-logout',
    component: (props: any) => <AutoAuthLogoutPage {...props} />,
    exact: true,
  },
  {
    path: '/auth/:tab',
    component: (props: any) => <AuthPage {...props} />,
    exact: true,
  },

  // {
  //   path: '/signup',
  //   component: (props: any) => <SignUpPage {...props} />,
  //   exact: true,
  // },
  {
    path: '/no-permission',
    component: (props: any) => <NoPermissionPage {...props} />,
    exact: true,
  },
  {
    path: '/my-companies',
    exact: true,
    component: (props: any) => <MyCompaniesPage {...props} />,
  },
  // {
  //   path: '/my-companies/create',
  //   exact: true,
  //   component: (props: any) => <CompanyCreatePage {...props} />,
  // },
  {
    path: '/invitation/:token',
    exact: true,
    component: (props: any) => <InvitationVerifyPage {...props} />,
  },
  // {
  //   path: '/company-setup/:tab',
  //   component: (props: any) => <CompanySetupPage {...props} />,
  //   exact: true,
  //   private: true,
  // },
  {
    path: '/test',
    exact: true,
    component: (props: any) => <MainDashboardWrapper {...props} />,
  },
]
