import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'

export const fetchCompanyPurchases = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(
        `${backendUrl}/hr/billing/get-company-purchases`
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchCompanyPurchase = async (id: String) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(
        `${backendUrl}/hr/billing/get-company-purchase`,
        {
          id,
        }
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchCompanyDetails = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(`${backendUrl}/hr/billing/get-company-details`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchCompanyBudgetRequest = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get<ApiResponseI>(
        `${backendUrl}/hr/billing/get-company-budget-requests`
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
