import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

interface PropsI extends RouteComponentProps {
  trigger: JSX.Element | ((isOpen: boolean) => JSX.Element)
}

const ScormPopup: React.FC<PropsI> = (props) => {
  const { trigger } = props

  return (
    <Popup
      trigger={trigger}
      modal
      contentStyle={{
        width: '70%',
        height: '80%',
        borderRadius: '5px',
      }}
      // closeOnDocumentClick={false}
      // onOpen={() => setItems((window as any).selectedRows)}
    >
      {(close: any) => (
        <iframe
          allow="fullscreen"
          width="100%"
          height="100%"
          src="https://client.app.skillground.com/?cid=ot6KLnZXgGnQPSJYDcKu"
        ></iframe>
      )}
    </Popup>
  )
}

export default withRouter(ScormPopup)
