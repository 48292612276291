import React, { useEffect } from 'react'
import { Button, MenuItem, Select, TextField } from '@material-ui/core'
import { QuestionType, SchemaType } from '../helpers'
import {
  Control,
  Controller,
  UseFormSetValue,
  useFieldArray,
  useFormContext,
} from 'react-hook-form'
import { XCircle } from 'react-feather'

type Props = {
  // question: QuestionType
  index: number
  // setValue: UseFormSetValue<SchemaType>
  // control: Control<SchemaType, any>
}

export default function Question({ index }: Props) {
  const { control, watch, setValue } = useFormContext()

  const { fields, append, remove, update } = useFieldArray({
    control: control,
    name: `questions.${index}.options`,
  })

  // const type = watch(`questions.${index}.type`)

  // console.log('🚀 ~ Question ~ test:', test)
  return (
    <div className="flex flex-col gap-5">
      <Controller
        name={`questions.${index}.name`}
        control={control}
        render={({ field }) => (
          <TextField
            label="Question"
            className="w-full"
            variant="outlined"
            value={field.value}
            onChange={(e) => {
              setValue(`questions.${index}.name`, e.target.value)
            }}
          />
        )}
      />

      {/* {type === 'options' && ( */}
      <div className="flex flex-col gap-5">
        {fields.map((option, index2) => (
          <div className="flex gap-5 items-center relative group/option" key={option.id}>
            <XCircle
              size={20}
              className="absolute -top-[10px] -right-[10px] bg-white rounded-full text-red-500 cursor-pointer transition-all z-50 opacity-0 group-hover/option:opacity-100"
              onClick={() => {
                remove(index2)
              }}
            />

            <p
              className="w-8 h-8 rounded-full flex items-center justify-center"
              style={{
                border: '1px solid #007373',
              }}
            >
              {index2 + 1}
            </p>
            <Controller
              name={`questions.${index}.options.${index2}.label`}
              control={control}
              render={({ field }) => (
                <TextField
                  label="Option"
                  className="flex-1"
                  variant="outlined"
                  value={field.value}
                  onChange={(e) => {
                    setValue(`questions.${index}.options.${index2}.label`, e.target.value)
                  }}
                />
              )}
            />
          </div>
        ))}

        <Button
          variant="contained"
          disableElevation
          onClick={() => {
            append({
              label: '',
              value: fields.length.toString(),
            })
          }}
        >
          Add Option
        </Button>
      </div>
      {/* )} */}
    </div>
  )
}
