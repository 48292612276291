import { RouteComponentProps, withRouter } from 'react-router-dom'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { useEffect, useState } from 'react'
import { getAllSurveys } from './helpers'
import BaseTable from '../../../components/BaseTable/BaseTable'
import TableActions from '../../../components/TableActions/TableActions'
import { faEdit, faEye, faHammer, faTrash } from '@fortawesome/free-solid-svg-icons'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import { PlusSquare } from 'react-feather'
import { useConfirm } from 'material-ui-confirm'

interface PropsI extends RouteComponentProps {}

const HRSurveyPage: React.FC<PropsI> = (props) => {
  const { history } = props

  const confirm = useConfirm()

  const [data, setData] = useState([])

  useEffect(() => {
    getAllSurveys().then((res) => {
      // console.log('🚀 ~ getAllSurveys ~ res:', res)
      setData(res)
    })
  }, [])

  const columns: any[] = [
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (tableProps: any) => (
        <TableActions
          isMenu={true}
          actions={[
            {
              icon: faEye,
              action: () => history.push(`/hr/survey/view/${tableProps.row.original.id}`),
              tooltip: 'View',
            },
            {
              icon: faEdit,
              action: () => history.push(`/hr/survey/edit/${tableProps.row.original.id}`),
              tooltip: 'Edit',
            },
            {
              icon: faHammer,
              action: () => history.push(`/hr/survey/builder/${tableProps.row.original.id}`),
              tooltip: 'Question Builder',
            },
            // {
            //   icon: faTrash,
            //   action: () => {
            //     // console.log('delete')
            //     confirm().then(() => {
            //       deleteCurriculum(tableProps.row.original.id).then(() => {
            //         pageLoad()
            //       })
            //     })
            //   },
            //   tooltip: 'Delete',
            //   buttonStyle: {
            //     backgroundColor: '#730000',
            //   },
            // },
          ]}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    // {
    //   Header: 'Is Disabled',
    //   accessor: 'isDisabled',
    // },
  ]

  return (
    <MainDashboardWrapper title="Survey" page="corp-survey">
      <div className="self-end">
        <PrimaryButton name={'Create'} to="/hr/survey/edit/new" icon={PlusSquare} />
      </div>
      <BaseTable
        columns={columns}
        data={data}
        // loading={this.state.dataLoading}
      />
    </MainDashboardWrapper>
  )
}

export default withRouter(HRSurveyPage)
