import { Button } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchCompanyPurchase } from '../HRBillingPage.helper'
import { Purchase } from './InvoiceType'
import moment from 'moment'

type Props = {}

export default function InvoiceReport({}: Props) {
  const { id } = useParams<{ id: string }>()
  const [purchase, setPurchase] = useState<Purchase | null>(null)
  console.log('🚀 ~ file: InvoiceReport.tsx:11 ~ InvoiceReport ~ purchase:', purchase)

  useEffect(() => {
    fetchCompanyPurchase(id).then((res) => {
      setPurchase(res.data)
    })
  }, [id])

  return (
    <div className="p-5 print:p-0">
      <div className="flex gap-2 print:hidden bg-slate-100 p-3">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            window.print()
          }}
        >
          Print
        </Button>
      </div>

      <div className="mt-5">
        <p className="text-4xl">Invoice Report</p>
        <table>
          <tbody>
            <tr>
              <td className="pr-2">Generated At:</td>
              <td>{moment().format('MMMM Do YYYY, h:mm:ss a')}</td>
            </tr>
            <tr>
              <td className="pr-2">Amount:</td>
              <td>Rs {purchase?.amount}</td>
            </tr>
            <tr>
              <td className="pr-2">Payment Method:</td>
              <td>{purchase?.paymentMethod}</td>
            </tr>
          </tbody>
        </table>

        <p className="text-2xl mt-5">Curriculum Details</p>

        <table className="p-0 m-0">
          <tbody>
            <tr>
              <td className="pr-2">Name:</td>
              <td>{purchase?.enrolledCurriculum.curriculum.name}</td>
            </tr>
            <tr>
              <td className="pr-2">Type:</td>
              <td>{purchase?.enrolledCurriculum.curriculum.type}</td>
            </tr>
            <tr>
              <td className="pr-2">Enrolled At:</td>
              <td>
                {moment(purchase?.enrolledCurriculum.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
              </td>
            </tr>
          </tbody>
        </table>

        <p className="text-2xl mt-5">Coach</p>

        <table className="p-0 m-0">
          <tbody>
            <tr>
              <td className="pr-2">Name:</td>
              <td>{purchase?.enrolledCurriculum.coach.displayName}</td>
            </tr>
            <tr>
              <td className="pr-2">Email:</td>
              <td>{purchase?.enrolledCurriculum.coach.email}</td>
            </tr>

            {purchase?.enrolledCurriculum.coach.location && (
              <tr>
                <td className="pr-2">Location:</td>
                <td>{purchase?.enrolledCurriculum.coach.location}</td>
              </tr>
            )}

            {purchase?.enrolledCurriculum.coach.phoneNumber && (
              <tr>
                <td className="pr-2">Phone Number:</td>
                <td>{purchase?.enrolledCurriculum.coach.phoneNumber}</td>
              </tr>
            )}

            {purchase?.enrolledCurriculum.coach.isPremium && (
              <tr>
                <td className="pr-2">Premium Coach:</td>
                <td>{purchase?.enrolledCurriculum.coach.isPremium ? 'Yes' : 'No'}</td>
              </tr>
            )}
          </tbody>
        </table>

        <p className="text-2xl mt-5">Attendee</p>

        <table className="p-0 m-0">
          <tbody>
            <tr>
              <td className="pr-2">Name:</td>
              <td>{purchase?.enrolledCurriculum.attendee.displayName}</td>
            </tr>
            <tr>
              <td className="pr-2">Email:</td>
              <td>{purchase?.enrolledCurriculum.attendee.email}</td>
            </tr>

            {purchase?.enrolledCurriculum.attendee.location && (
              <tr>
                <td className="pr-2">Location:</td>
                <td>{purchase?.enrolledCurriculum.attendee.location}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
