import React from 'react'
import { ArrowRight } from 'react-feather'

type Props = {}

export default function Notifications({}: Props) {
  return (
    <div className="border border-gray-400 border-solid p-5 flex flex-col gap-2">
      <p className="mb-4 font-semibold text-gray-600">Action items</p>
      <div className="flex items-center">
        <p className="text-3xl w-12">6</p>
        <p className="flex flex-1">Pending Budget Requests</p>
      </div>
      <div className="flex items-center">
        <p className="text-3xl w-12">89</p>
        <p className="flex flex-1">Coaching Sessions in Progress</p>
      </div>
      <div className="flex items-center">
        <p className="text-3xl w-12">23</p>
        <p className="flex flex-1">Pending Employee Invites</p>
      </div>
    </div>
  )
}
