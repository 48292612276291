import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Button, TextField as MuiTextField } from '@material-ui/core'

import * as yup from 'yup'

import SmallRoundColoseButton from '../../../../components/SmallRoundCloseButton/SmallRoundCloseButton'
import { useAlert } from 'react-alert'
import css from './BulkInvitePopup.module.scss'
import { useConfirm } from 'material-ui-confirm'
import BaseTable from '../../../../components/BaseTable/BaseTable'
import { Column } from 'react-table'

import { Autocomplete } from '@material-ui/lab'
import { getCompanyRoles } from '../../../../helpers/authentication'
import { inviteBulkUser } from '../AddHREmployeesPage.helper'

interface FormValuesI {
  email: string
}

interface PropsI extends RouteComponentProps {
  trigger: JSX.Element | ((isOpen: boolean) => JSX.Element)
  data: any[]
  pageLoad: () => void
}

const BulkInvitePopup: React.FC<PropsI> = (props) => {
  const { trigger, data, pageLoad } = props
  const confirm = useConfirm()
  const alert = useAlert()

  const [mapData, setMapData] = useState<any[]>([])
  const [bulkRoles, setBulkRoles] = useState<string[]>([])

  useEffect(() => {
    const withRoles = data.map((x) => {
      return {
        email: x,
        roles: ['employee'],
      }
    })
    setMapData(withRoles)
  }, [data])

  // console.log({ mapData })
  let roleOptions = ['hr', 'employee']
  const roles = getCompanyRoles()
  if (roles.includes('admin')) {
    roleOptions.push('admin')
  }

  const columns: Column[] = [
    {
      Header: 'Email',
      accessor: 'email',
      Cell: (cellProps) => {
        const isValid = yup.string().email().isValidSync(cellProps.value)
        return (
          <span>
            {cellProps.value}{' '}
            {!isValid && <span style={!isValid ? { color: 'red' } : {}}>(Email Is Not Valid)</span>}
          </span>
        )
      },
    },
    {
      Header: 'Roles',
      accessor: 'roles',
      Cell: (cellProps: any) => {
        // console.log({ cellProps })
        const isValid = yup.string().email().isValidSync(cellProps.row.original.email)
        return (
          <Autocomplete
            disabled={!isValid}
            size={'small'}
            value={cellProps.value}
            multiple
            options={roleOptions}
            onChange={(event, value) => {
              // formikProps.setFieldValue('roles', value)
              let updatedData = [...mapData]
              updatedData[cellProps?.row?.index].roles = value
              setMapData(updatedData)
            }}
            // getOptionLabel={(option) => option.name}
            // getOptionSelected={(option, value) => option.id === value.id}
            filterSelectedOptions
            renderInput={(params) => (
              <MuiTextField
                {...params}
                className={css.input_field}
                variant="outlined"
                label="Roles *"
              />
            )}
          />
        )
      },
    },
  ]

  return (
    <Popup trigger={trigger} modal closeOnDocumentClick={false}>
      {(close: any) => (
        <div className={css.base}>
          <SmallRoundColoseButton onClick={close} className={css.close_button} />
          <p className={css.title}>Invite Bulk Users</p>
          {/*<p>*/}
          {/*  {data.slice(0, 3).map((x) => {*/}
          {/*    return <span>{x} </span>*/}
          {/*  })}*/}
          {/*  ... ({data.length})*/}
          {/*</p>*/}

          <p style={{ marginTop: 20 }}>Set roles for every users</p>
          <div className={css.all_roles}>
            <Autocomplete
              fullWidth
              size={'small'}
              multiple
              options={roleOptions}
              onChange={(event, value) => {
                setBulkRoles(value)
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  // className={css.input_field}
                  variant="outlined"
                  label="Roles *"
                />
              )}
            />
            <Button
              disabled={bulkRoles.length < 1}
              color="primary"
              variant="contained"
              type="submit"
              disableElevation
              onClick={() => {
                confirm().then(() => {
                  let updatdData = [...mapData]
                  for (let i = 0; i < updatdData.length; i++) {
                    updatdData[i].roles = bulkRoles
                  }
                  setMapData(updatdData)
                })
              }}
            >
              Set Roles
            </Button>
          </div>

          <BaseTable columns={columns} data={mapData} />

          <Button
            className={css.submit_button}
            color="primary"
            variant="contained"
            type="submit"
            disableElevation
            onClick={() => {
              inviteBulkUser(mapData).then((res) => {
                alert.success(res.message)
                close()
                pageLoad()
              })
            }}
          >
            Invite All
          </Button>
        </div>
      )}
    </Popup>
  )
}

export default withRouter(BulkInvitePopup)
