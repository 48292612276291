// @ts-nocheck

import React, { BaseSyntheticEvent, Component } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Tab, Tabs } from '@material-ui/core'
import EmployeeCompanyMyCurriculumsView from './EmployeeCompanyMyCurriculumsView/EmployeeCompanyMyCurriculumsView'
import EmployeeCompanyMyBudgetView from './EmployeeCompanyMyBudgetView/EmployeeCompanyMyBudgetView'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import KnowledgeCentreView from './KnowledgeCentreView/KnowledgeCentreView'
import EventsView from './EventsView/EventsView'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps<{ tab: string }> {}

interface StatesI {}

const TABS = ['my-curriculum', 'my-budget', 'knowledge-centre', 'events']

class EmployeeCompanyPage extends Component<PropsI, StatesI, any> {
  constructor(props: PropsI) {
    super(props)

    this.state = {}

    this.onTabChange = this.onTabChange.bind(this)
  }

  componentDidMount() {}

  onTabChange(event: BaseSyntheticEvent, tabIndex: number) {
    const { history } = this.props
    switch (tabIndex) {
      case 0:
        history.push(`/employee/company/${TABS[0]}`)
        break

      case 1:
        history.push(`/employee/company/${TABS[1]}`)
        break

      case 2:
        history.push(`/employee/company/${TABS[2]}`)
        break

      case 3:
        history.push(`/employee/company/${TABS[3]}`)
        break
    }
  }

  getIndexByParams(tab: string) {
    switch (tab) {
      case TABS[0]:
        return 0

      case TABS[1]:
        return 1

      case TABS[2]:
        return 2

      case TABS[3]:
        return 3

      default:
        return 0
    }
  }

  renderComponentByTab(tab: string) {
    switch (tab) {
      case TABS[0]:
        return <EmployeeCompanyMyCurriculumsView />

      case TABS[1]:
        return <KnowledgeCentreView />

      case TABS[2]:
        return <EventsView />

      case TABS[3]:
        return <EmployeeCompanyMyBudgetView />

      default:
        return <EmployeeCompanyMyCurriculumsView />
    }
  }

  render() {
    const { currentCompany, match } = this.props

    const selectedTabIndex = this.getIndexByParams(match.params.tab)
    return (
      <MainDashboardWrapper
        title={`V-Client Company (${currentCompany?.name})`}
        page="client-company"
      >
        <Tabs
          value={selectedTabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.onTabChange}
          // aria-label="disabled tabs example"
        >
          <Tab label="My Curriculums" />
          <Tab label="Knowledge Centre" />
          <Tab label="Events / Webinars" />
          <Tab label="My Budget" />
        </Tabs>
        {this.renderComponentByTab(match.params.tab)}
      </MainDashboardWrapper>
    )
  }
}

interface MapStatePropsI {
  currentCompany: any
}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    currentCompany: state.user.currentCompany,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeCompanyPage))
