import { RouteComponentProps, useParams, withRouter } from 'react-router-dom'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { useEffect, useState } from 'react'
import { getASurvey } from './helpers'
import { Survey } from '../../../types/survey'
import { Bar } from 'react-chartjs-2'

interface PropsI extends RouteComponentProps {}

// const horizontalOptions = {
//   indexAxis: 'y' as const,
//   elements: {
//     bar: {
//       borderWidth: 2,
//     },
//   },
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'right' as const,
//     },
//     title: {
//       display: true,
//       text: 'Employee Budget Utillization',
//     },
//   },
// }

// const horizontalData = {
//   labels: ['JanJanJanJanJanJanJan JanJanJan', 'Feb', 'Mar', 'Apr', 'May'],
//   datasets: [
//     {
//       label: 'Utillized ',
//       data: [1, 3, 2, 6, 1, 2],
//       yAxisID: 'y',
//       // backgroundColor: css.chartColor3,
//       // borderColor: css.chartColor3,
//     },
//   ],
// }

const HRSurveyViewPage: React.FC<PropsI> = (props) => {
  const { history } = props

  const [survey, setSurvey] = useState<Survey | null>(null)
  // console.log('🚀 ~ survey:', survey)

  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    getASurvey(id).then((data) => {
      setSurvey(data)
    })
  }, [id])

  return (
    <MainDashboardWrapper title="Survey View" page="corp-survey">
      <h1 className="m-0">{survey?.name}</h1>
      <p className="m-0 text-gray-500">{survey?.description}</p>

      <div className="mt-5">Curriculum: {survey?.curriculum.name}</div>

      <div className="mt-5 flex flex-col gap-5">
        {survey?.questions?.map((quest) => {
          return (
            <div
              className="p-5 rounded-md"
              style={{
                border: '1px solid #c6c6c6',
              }}
            >
              <div className="font-semibold">{quest.name} ?</div>
              <div className="flex flex-col gap-2 mt-3">
                {quest.options?.map((opt) => {
                  return (
                    <div
                      className="flex gap-2 items-center hover:bg-gray-200 px-2 py-1 rounded-md cursor-pointer"
                      onClick={() => {
                        history.push(
                          `/hr/survey/view/${id}/question/${quest.id}/answer/${opt.value}`
                        )
                      }}
                    >
                      <div
                        className="w-5 h-5 rounded-full flex items-center justify-center text-sm"
                        style={{
                          border: '1px solid black',
                        }}
                      >
                        {quest.answers.filter((x) => x.answer === opt.value).length}
                      </div>
                      <div className="text-gray-500 flex-1">{opt.label}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>

      {/* <Bar options={horizontalOptions} data={horizontalData} /> */}
    </MainDashboardWrapper>
  )
}

export default withRouter(HRSurveyViewPage)
