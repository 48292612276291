import { faFile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import css from './KnowledgeCentreView.module.scss'
import ScormPopup from './ScormPopup'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {}

const KnowledgeCentreView: React.FC<PropsI> = (props) => {
  const cardsArr = [
    {
      title: 'Training Modules',
      items: [
        {
          name: 'Introduction to Product Management Module.pdf',
        },
        {
          name: 'Sales Techniques Workshop Video.mp4',
        },
        {
          name: 'Leadership Skills Assessment Form.docx',
        },
        {
          name: 'Cybersecurity Best Practices Guidebook.pdf',
        },
        {
          name: 'Excel Basics Training Slides.pptx',
        },
      ],
    },
    {
      title: 'Assessment Tools',
      items: [
        {
          name: 'Skill Proficiency Assessment',
        },
        {
          name: 'Leadership Competency Assessment',
        },
        {
          name: 'Customer Service Aptitude Test',
        },
        {
          name: 'Technical Skills Test',
        },
      ],
    },
    {
      title: 'Learning Resources',
      items: [
        {
          name: 'The Art of Effective Communication - E-Book',
        },
        {
          name: 'Data Science Essentials: A Comprehensive Guide - Whitepaper',
        },
        {
          name: 'Strategic Leadership in the Digital Age - Article',
        },
        {
          name: 'Financial Management Basics - Online Course',
        },
      ],
    },
    {
      title: 'Video Tutorials',
      items: [
        {
          name: 'Mastering Client Interaction: Effective Communication Techniques',
        },
        {
          name: 'Strategies for Goal Setting and Achievement',
        },
        {
          name: 'Navigating Challenging Conversations with Confidence',
        },
        {
          name: 'Time Management Mastery for Increased Productivity',
        },
        {
          name: 'Unlocking Leadership Potential: Key Leadership Skills',
        },
        {
          name: 'Introduction to Mindfulness in the Workplace',
        },
      ],
    },
  ]

  return (
    <div className={css.base}>
      <div className={css.cards}>
        {cardsArr.map((x, i) => {
          return (
            <div key={i} className={css.card}>
              <p className={css.title}>{x.title}</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                  marginTop: 5,
                }}
              >
                {x.items.map((y) => {
                  return <p className={css.link_item}>{y.name}</p>
                })}
              </div>
            </div>
          )
        })}
      </div>

      <p
        className={css.title}
        style={{
          marginTop: 20,
        }}
      >
        SCORM Files
      </p>
      <div className={css.scorn_files}>
        {new Array(5).fill(0).map((x, i) => {
          return (
            <ScormPopup
              trigger={
                <div className="flex flex-col gap-2">
                  <FontAwesomeIcon size="5x" icon={faFile} />
                  <p className="text-black">Leadership File {i + 1}.0</p>
                </div>
              }
            />
          )
        })}
      </div>
    </div>
  )
}

interface MapStatePropsI {
  // currentCompany: any
}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    // currentCompany: state.user.currentCompany,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KnowledgeCentreView))
