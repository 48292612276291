import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import css from './colors.module.scss'
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { purchase } from '../HRDashboard.helper'

type Props = {}

export default function Purchases({}: Props) {
  const [data, setData] = useState<any>()
  console.log('🚀 ~ file: Purchases.tsx:18 ~ Purchases ~ data:', data)

  useEffect(() => {
    purchase().then((res) => setData(res.data))
  }, [])

  const labels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const barData = {
    labels: labels,
    datasets: [
      {
        label: 'Purchase',
        data: [1, 5, 3, 0],
        // yAxisID: 'y1',
        backgroundColor: css.chartColor1,
        borderColor: css.chartColor1,
      },
    ],
  }

  return (
    <div className="border border-gray-300 border-solid p-5 bg-white">
      <p className="font-semibold">Purchases</p>
      <div className="flex justify-between items-center py-2">
        <FormControl
          style={{
            width: 200,
          }}
        >
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={10}
            // onChange={handleChange}
          >
            <MenuItem value={10}>Last 7 days</MenuItem>
            <MenuItem value={20}>Last 30 days</MenuItem>
          </Select>
        </FormControl>

        <Button variant="contained">Report</Button>
      </div>
      <Bar data={barData} />
    </div>
  )
}
