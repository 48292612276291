import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'

// export const fetchDashboardData = async () => {
//   return new Promise<ApiResponseI>(async (resolve, reject) => {
//     try {
//       const res = await authAxios.post(`${backendUrl}/hr/dashboard/get-data`)
//       resolve(res.data)
//     } catch (err) {
//       reject(err.response?.data.message || err.message)
//     }
//   })
// }
//
export const fetchEmployeesDashboardMonthly = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/dashboard/employee-dashboard-data-monthly`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchEmployeesDashboardYears = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/dashboard/employee-dashboard-data-yearly`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchDashboardDataMonthly = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/dashboard/dashboard-data-monthly`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchDashboardDataYearly = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/dashboard/dashboard-data-yearly`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const purchase = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/dashboard/purchases`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
