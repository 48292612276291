import { z } from 'zod'
import { backendUrl } from '../../../env-config'
import { authAxios } from '../../../helpers/axios'
import { Survey } from '../../../types/survey'

export const questionSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(1).max(255),
  type: z.string().min(1).max(255),
  // data: z.any().optional(),
  options: z
    .array(
      z.object({
        label: z.string().min(1).max(255),
        value: z.string().min(1).max(255),
      })
    )
    .optional(),
})

export const schema = z.object({
  id: z.string().optional(),
  questions: z.array(questionSchema),
})

export type SchemaType = z.infer<typeof schema>
export type QuestionType = z.infer<typeof questionSchema>

export const createOrUpdateASurvey = async (data: any) => {
  try {
    const res = await authAxios.post(`${backendUrl}/survey/createOrUpdateASurvey`, data)
    return res.data
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getAllSurveys = async () => {
  try {
    const res = await authAxios.get(`${backendUrl}/survey/getAllSurveys`)
    return res.data
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getASurvey = async (id: string) => {
  try {
    const res = await authAxios.post(`${backendUrl}/survey/getASurvey`, {
      id: id,
    })
    return res.data as Survey
  } catch (error) {
    console.log(error)
    return null
  }
}

export const saveQuestions = async (data: any) => {
  try {
    const res = await authAxios.post(`${backendUrl}/survey/saveQuestions`, data)
    return res.data
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getQuestions = async (id: string) => {
  try {
    const res = await authAxios.post(`${backendUrl}/survey/getQuestions`, {
      id,
    })
    return res.data
  } catch (error) {
    console.log(error)
    return null
  }
}

export const getAnswers = async (questionId: string, answer: string) => {
  try {
    const res = await authAxios.post(`${backendUrl}/survey/getAnswers`, {
      questionId,
      answer,
    })
    return res.data
  } catch (error) {
    console.log(error)
    return null
  }
}
