import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import css from './LoginTab.module.scss'
import { Field, Form, Formik, FormikProps } from 'formik'
import { Button, CircularProgress } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import * as yup from 'yup'
import { FormikHelpers } from 'formik/dist/types'
import { AlertManager, withAlert } from 'react-alert'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import { login } from '../../../ducks/auth.duck'
import { vivekaUrl } from '../../../env-config'

interface LocationStateI {
  email: string
  from: string
}
interface PropsI
  extends MapStatePropsI,
    MapDispatchPropsI,
    RouteComponentProps<any, any, LocationStateI> {
  alert: AlertManager
  email?: string
}

interface FormFieldsI {
  email: string
  password: string
}

const LoginTab: React.FC<PropsI> = (props) => {
  const { alert, history, loginA, location } = props

  const email = location?.state?.email

  const onSubmit = (values: FormFieldsI, formikHelpers: FormikHelpers<FormFieldsI>) => {
    loginA(values.email, values.password)
      .then((res) => {
        // formikHelpers.setSubmitting(false)

        if (res.data.roles.includes('hr')) {
          history.push(location?.state?.from || '/hr')
        }

        if (res.data.roles.includes('employee')) {
          history.push(location?.state?.from || '/employee')
        }

        if (res.data.roles.includes('coach')) {
          history.push(location?.state?.from || '/coach')
        }

        window.open(
          `${vivekaUrl}/auto-login?e=${btoa(values.email)}&p=${btoa(values.password)}`,
          '_blank'
        )

        history.push(location?.state?.from || '/')
      })
      .catch((err) => {
        alert.error(err)
        formikHelpers.setSubmitting(false)
      })
  }

  const initialValues: FormFieldsI = {
    email: email || '',
    password: '',
  }

  const validationSchema = yup.object({
    email: yup.string().label('Email').required().email(),
    password: yup.string().label('Password').min(8).required(),
  })

  return (
    <div className={css.base}>
      <p className={css.title}>Login to V-Corp</p>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formikProps: FormikProps<FormFieldsI>) => {
          return (
            <Form className={css.form}>
              <Field
                className={css.input_field}
                component={TextField}
                name="email"
                variant="outlined"
                label="Email"
                color="primary"
                required
                disabled={!!email}
              />

              <Field
                className={css.input_field}
                component={TextField}
                type="password"
                name="password"
                variant="outlined"
                label="Password"
                color="primary"
                required
              />

              <Button
                className={css.submit_button}
                color="primary"
                variant="contained"
                type="submit"
                disableElevation
                disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
              >
                {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Login'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

interface MapStatePropsI {
  currentUser: any
}

interface MapDispatchPropsI {
  loginA: (email: string, password: string) => Promise<ApiResponseI>
}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    currentUser: state.user.currentUser,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {
    loginA: (email: string, password: string) => dispatch(login(email, password)),
  }
}

export default withAlert()(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginTab)))
