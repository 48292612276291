import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import css from './HRCurriculumEditListings.module.scss'
import { ThunkDispatch } from 'redux-thunk'
import { Column } from 'react-table'
import { Avatar } from '@material-ui/core'
import { useAlert } from 'react-alert'
import { useDebouncedCallback } from 'use-debounce'
import {
  addListingToCurriculum,
  fetchCoachesForCurriculum,
  fetchListingsFromMyCoaches,
  fetchListingsInACurriculum,
  removeListingFromCurriculum,
} from './HRCurriculumEditListings.helper'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import TableActions from '../../../components/TableActions/TableActions'
import { faFolderOpen, faTrash } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser from 'react-html-parser'
import { decode } from 'html-entities'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import Stepper from '../../../components/Stepper/Stepper'
import CurriculumCoachOfferingPopup from '../../../components/CurriculumCoachOfferingPopup/CurriculumCoachOfferingPopup'
import { vivekaUrl } from '../../../env-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps<{ id: string }> {}

const HRCurriculumEditListings: React.FC<PropsI> = (props) => {
  const { match, history } = props
  const [searchText, setSearchText] = useState('')
  const [listings, setListings] = useState<any[]>([])
  const [sListings, setSListings] = useState<any[]>([])
  const [coaches, setCoaches] = useState<any[]>([])

  // const confirm = useConfirm()

  const loadPage = useCallback(() => {
    fetchListingsInACurriculum(match.params.id || '').then((res) => {
      setSListings(res.data)
    })
    fetchCoachesForCurriculum(match.params.id).then((res) => setCoaches(res.data))
  }, [match.params.id])

  useEffect(() => {
    loadPage()
  }, [loadPage])

  const alert = useAlert()

  const fetchListings = useDebouncedCallback(
    (value: string) => {
      fetchListingsFromMyCoaches(value, match.params.id).then((res) => {
        setListings(res.data)
      })
    },
    600,
    { maxWait: 2000 }
  )

  const searchTextOnChange = (value: string) => {
    setSearchText(value)
    fetchListings(value)
  }

  const addListingButtonHandle = (listingId: number) => {
    addListingToCurriculum(listingId, Number(match.params.id))
      .then((res) => {
        alert.success('Listing added to the curriculum')
        window.alert('Listing added to the curriculum')
        loadPage()
      })
      .catch((err) => {
        window.alert(err)
        alert.error(err)
      })
  }

  const removeButtonHandle = (listingId: number) => {
    removeListingFromCurriculum(listingId, Number(match.params.id))
      .then((res) => {
        alert.success('Listing removed from the curriculum')
        loadPage()
      })
      .catch((err) => {
        alert.error(err)
      })
  }

  const columns: Column[] = [
    {
      Header: 'Listing',
      accessor: 'name',
      Cell: (props: any) => {
        const img = props.row.original?.images[0]
        return (
          <span className={css.image_cell}>
            <Avatar src={img || ''}>{props.value?.charAt(0)}</Avatar>
            <span>{props.value}</span>
          </span>
        )
      },
    },
    {
      Header: 'Coach',
      accessor: 'owner',
      Cell: (props: any) => {
        if (!props.value) return ''

        const img = props.value.profilePic
        return (
          <span className={css.image_cell}>
            <Avatar src={img || ''}>{props.value?.displayName?.charAt(0)}</Avatar>
            <span>{props.value.displayName}</span>
          </span>
        )
      },
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: (tableProps: any) => {
        if (tableProps.value && tableProps.value !== 0) {
          return '$' + tableProps?.value?.toLocaleString()
        } else {
          return '$0'
        }
      },
    },
    {
      Header: 'Action',
      Cell: (tableProps: any) => (
        <TableActions
          actions={[
            {
              icon: faTrash,
              action: () => removeButtonHandle(tableProps.row.original.id),
              tooltip: 'Delete',
            },
          ]}
        />
      ),
      disableSortBy: true,
    },
  ]

  console.log({ sListings })

  let coachListings: any[] = []

  coaches.forEach((x) => {
    x?.listings?.forEach((y: any) => {
      coachListings.push({
        coach: x,
        listing: y,
      })
    })
  })

  console.log({ coaches, coachListings })

  return (
    <MainDashboardWrapper title="Update Listings" page="corp-curriculum">
      <Stepper
        steps={[
          {
            title: `Add Curriculum`,
            isComplete: true,
          },
          {
            title: 'Add Listings',
            isSelected: true,
          },
          {
            title: 'Add Employees',
          },
        ]}
      />
      <div className={css.base}>
        <div
          onClick={() => history.push(`/hr/curriculum/edit/employees/${match.params.id}`)}
          className={css.continue_button}
        >
          Continue
        </div>

        {/*<BaseTable columns={columns} data={sListings} />*/}

        {/*<div className={css.listings}>*/}
        {/*  {sListings.map((item) => {*/}
        {/*    return (*/}
        {/*      <div key={item.id} className={css.coach_card_base}>*/}
        {/*        <div className={css.coach_name_base}>*/}
        {/*          <Avatar src={item.images?.length > 0 ? item.images[0] : ''} />*/}
        {/*          <div className={css.coach_name}>*/}
        {/*            <p>{item.name}</p>*/}
        {/*            <p>{item.type}</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}

        {/*        <div className={css.coach_description}>*/}
        {/*          {ReactHtmlParser(decode(item?.description?.substring(0, 200)))}*/}
        {/*        </div>*/}

        {/*        <div className={css.listing_actions}>*/}
        {/*          <div className={css.listing_price}>${(item.price || 0).toLocaleString()}</div>*/}
        {/*          <div className={css.listing_button} onClick={() => removeButtonHandle(item.id)}>*/}
        {/*            Remove*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    )*/}
        {/*  })}*/}
        {/*</div>*/}
        <div className={css.listings}>
          {sListings.map((item) => {
            return (
              <div key={item?.id} className={css.listing_card}>
                <img className={css.listing_image} alt="listing" src={item?.images[0] || ''} />
                <div className={css.listing_details}>
                  <a>{item?.name}</a>
                  {/*<a*/}
                  {/*  href={`${vivekaUrl}/u/${item?.coach.uuid}`}*/}
                  {/*  target="_blank"*/}
                  {/*  className={css.coach_name}*/}
                  {/*>*/}
                  {/*  {item?.coach.displayName}*/}
                  {/*</a>*/}
                  <a
                    href={`${vivekaUrl}/u/${item?.owner.uuid}`}
                    target="_blank"
                    className={css.coach_name}
                  >
                    {item?.owner.displayName}
                  </a>
                  <div className={css.listing_description}>
                    {ReactHtmlParser(decode(item?.description))}
                  </div>
                  <div className={css.listing_categories}>
                    {item?.typeOfService?.map((t: any, i: number) => {
                      return <div key={i}>{t}</div>
                    })}
                  </div>
                </div>
                <div className={css.listing_action}>
                  <p>
                    ${item?.price.toLocaleString()} / {item?.type}
                  </p>
                  {/*<Link to={`${window.location.pathname}/${item.id}`} className={css.button}>*/}
                  {/*  Enroll*/}
                  {/*</Link>  */}

                  <div className={css.button3} onClick={() => removeButtonHandle(item?.id)}>
                    Remove
                  </div>

                  <a className={css.button2} href={`${vivekaUrl}/l/${item?.uuid}`} target="_blank">
                    View
                  </a>
                </div>
              </div>
            )
          })}
        </div>

        {/*<p style={{ margin: '20px 0', color: 'gray' }}>*/}
        {/*  Add your preferred coaches from coach section, And add listings*/}
        {/*</p>*/}
        {/*<TextField*/}
        {/*  label="Add Listing"*/}
        {/*  placeholder="Start typing"*/}
        {/*  variant={'outlined'}*/}
        {/*  value={searchText}*/}
        {/*  onChange={(event) => searchTextOnChange(event.target.value)}*/}
        {/*/>*/}
      </div>
      {/*<div className={css.listingCards}>*/}
      {/*  {listings.map((item: any, index: number) => {*/}
      {/*    // const description = item.description.slice(0, 120)*/}
      {/*    const description = item.description*/}
      {/*    return (*/}
      {/*      <div key={index} className={css.cardBase}>*/}
      {/*        <p className={css.cardTitle}>{item.name}</p>*/}
      {/*        <p>*/}
      {/*          ${(item.price || 0).toLocaleString()} {item.type}*/}
      {/*        </p>*/}
      {/*        <div className={css.cardDesc}>{ReactHtmlParser(decode(description))}...</div>*/}
      {/*        /!*<p className={css.cardDesc}>{ReactHtmlParser(encode(item.description))}</p>*!/*/}

      {/*        <div className={css.button}>*/}
      {/*          <Button*/}
      {/*            style={{ borderRadius: '2px' }}*/}
      {/*            variant="contained"*/}
      {/*            color="primary"*/}
      {/*            size="small"*/}
      {/*            onClick={() => addListingButtonHandle(item.id)}*/}
      {/*          >*/}
      {/*            Add To Curriculum*/}
      {/*          </Button>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</div>*/}

      <div className={css.recommended_coaches}>
        <p className={css.coach_title}>
          Recommended coaches filtered by price range and categories
        </p>
        <div className={css.coaches}>
          {coachListings.length == 0 && (
            <div className="flex gap-5 items-center">
              <FontAwesomeIcon icon={faFolderOpen} size="3x" className="text-gray-400" />
              <p className="text-gray-400">
                No listings matching for this curriculum. <br /> Add more Coaches to the company to
                see more listings.
              </p>
            </div>
          )}
          {/*{coaches.map((item) => {*/}

          {coachListings.map((item) => {
            const isSelected = sListings?.find((x) => x.id === item?.listing.id)
            if (isSelected) return null
            return (
              // <div key={item.id} className={css.coach_card_base}>
              //   <div className={css.coach_name_base}>
              //     <Avatar src={item.profilePic} />
              //     <div className={css.coach_name}>
              //       <p>{item.displayName}</p>
              //       <p>{item.location}</p>
              //     </div>
              //   </div>
              //
              //   <div className={css.coach_description}>
              //     {ReactHtmlParser(decode(item?.bio?.substring(0, 200)))}
              //   </div>
              //
              //   <div className={css.coach_actions}>
              //     <CurriculumCoachOfferingPopup
              //       coach={item}
              //       trigger={<div className={css.coach_button}>See Programs</div>}
              //       onSelectCurriculum={addListingButtonHandle}
              //     />
              //   </div>
              // </div>
              //
              <div key={item?.listing.id} className={css.listing_card}>
                <img
                  className={css.listing_image}
                  alt="listing"
                  src={item?.listing.images[0] || ''}
                />
                <div className={css.listing_details}>
                  <a>{item?.listing.name}</a>
                  <a
                    href={`${vivekaUrl}/u/${item?.coach.uuid}`}
                    target="_blank"
                    className={css.coach_name}
                  >
                    {item?.coach.displayName}
                  </a>
                  <div className={css.listing_description}>
                    {ReactHtmlParser(decode(item?.listing.description))}
                  </div>
                  <div className={css.listing_categories}>
                    {item?.listing?.typeOfService?.map((t: any, i: number) => {
                      return <div key={i}>{t}</div>
                    })}
                  </div>
                </div>
                <div className={css.listing_action}>
                  <p>
                    ${item?.listing.price.toLocaleString()} / {item?.listing.type}
                  </p>
                  {/*<Link to={`${window.location.pathname}/${item.id}`} className={css.button}>*/}
                  {/*  Enroll*/}
                  {/*</Link>  */}

                  {!sListings?.find((x) => x.id === item?.listing.id) && (
                    <div
                      className={css.button3}
                      onClick={() => addListingButtonHandle(item?.listing.id)}
                    >
                      Add to curriculum
                    </div>
                  )}

                  <a
                    className={css.button2}
                    href={`${vivekaUrl}/l/${item?.listing.uuid}`}
                    target="_blank"
                  >
                    View
                  </a>
                </div>
              </div>
            )
          })}
        </div>

        {/*<div className={css.coaches}>*/}
        {/*  {coaches.map((item) => {*/}
        {/*    return (*/}
        {/*      <div key={item.id} className={css.coach_card_base}>*/}
        {/*        <div className={css.coach_name_base}>*/}
        {/*          <Avatar src={item.profilePic} />*/}
        {/*          <div className={css.coach_name}>*/}
        {/*            <p>{item.displayName}</p>*/}
        {/*            <p>{item.location}</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}

        {/*        <div className={css.coach_description}>*/}
        {/*          {ReactHtmlParser(decode(item?.bio?.substring(0, 200)))}*/}
        {/*        </div>*/}

        {/*        <div className={css.coach_actions}>*/}
        {/*          <CurriculumCoachOfferingPopup*/}
        {/*            coach={item}*/}
        {/*            trigger={<div className={css.coach_button}>See Programs</div>}*/}
        {/*            onSelectCurriculum={addListingButtonHandle}*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    )*/}
        {/*  })}*/}
        {/*</div>*/}
      </div>
    </MainDashboardWrapper>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HRCurriculumEditListings))
