import { Button, MenuItem, Select } from '@material-ui/core'
import { Field, FieldArray } from 'formik'
import { TextField } from 'formik-material-ui'
import React, { useEffect, useState } from 'react'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import Question from './components/Question'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { SchemaType, getQuestions, questionSchema, saveQuestions, schema } from './helpers'
import { XCircle } from 'react-feather'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'

type Props = {}

export default function HRSurveyQuestionBuilderPage({}: Props) {
  const [updateFlag, setUpdateFlag] = useState(false) // State variable for triggering re-renders

  const form = useForm<SchemaType>({
    resolver: zodResolver(schema),

    defaultValues: {
      questions: [
        // {
        //   name: 'Question 1',
        //   type: 'options',
        //   options: [],
        // },
      ],
    },
  })

  const { id } = useParams<{ id: string }>()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = form

  const { fields, append, remove, update } = useFieldArray({
    control: control,
    name: 'questions',
  })

  console.log(errors)

  // watch('questions')

  const alert = useAlert()

  useEffect(() => {
    getQuestions(id).then((res) => {
      // for (let index = 0; index < res.length; index++) {
      //   const el = res[index]

      //   setValue(`questions.${index}.name`, el.name)
      //   setValue(`questions.${index}.type`, el.type)

      //   for (let index2 = 0; index2 < el.options?.length; index2++) {
      //     const el2 = el.options[index2]

      //     setValue(`questions.${index}.options.${index2}.label`, el2.label)
      //     setValue(`questions.${index}.options.${index2}.value`, el2.value)
      //   }
      // }

      setValue(
        'questions',
        res.map((x: any) => {
          return {
            id: x.id,
            name: x.name,
            type: x.type,
            options: x.options,
          }
        })
      )
    })
  }, [id, setValue])

  // console.log('🚀 ~ HRSurveyQuestionBuilderPage ~ questions:', questions)

  return (
    <MainDashboardWrapper title="Survey Question Builder" page="corp-survey">
      <FormProvider {...form}>
        <div className="flex flex-col gap-10">
          {fields.map((q, index) => {
            return (
              <div
                key={q.id}
                className="grid gap-5 p-5 rounded-md relative group"
                style={{
                  border: '1px solid #007373',
                }}
              >
                <XCircle
                  size={20}
                  className="absolute -top-[10px] -right-[10px] bg-white rounded-full text-red-500 cursor-pointer opacity-0 group-hover:opacity-100 transition-all"
                  onClick={() => {
                    remove(index)
                  }}
                />

                <Question
                  // question={q}
                  // control={control}
                  // setValue={setValue}
                  index={index}
                />

                {/* <div>
                  <Controller
                    name={`questions.${index}.type`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        variant="outlined"
                        className="w-full"
                        value={field.value}
                        // {...register(`questions.${index}.type`)}
                        onChange={(e) => {
                          // console.log(e.target.value)
                          setValue(`questions.${index}.type`, e.target.value as string)
                        }}
                      >
                        <MenuItem value={'text'}>Text</MenuItem>
                        <MenuItem value={'options'}>Options</MenuItem>
                      </Select>
                    )}
                  />
                </div> */}
              </div>
            )
          })}

          <div className="flex gap-5">
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                append({
                  name: '',
                  type: 'options',
                })
              }}
            >
              Add Question
            </Button>

            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={handleSubmit((values) => {
                console.log({ values })
                values.id = id

                saveQuestions(values).then(() => {
                  alert.success('Questions Saved')
                })
              })}
            >
              Save
            </Button>
          </div>
        </div>
      </FormProvider>
    </MainDashboardWrapper>
  )
}
