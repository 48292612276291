import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { Field, Form, Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import { TextField } from 'formik-material-ui'
import css from './HRSettingPage.module.scss'
import { fetchCompanySettings, imageUpload, saveCompanySettings } from './HRSettingsPage.helper'
import { FormikHelpers } from 'formik/dist/types'
import NumberFormatInputField from '../../../components/NumberFormatInputField/NumberFormatInputField'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { PhotoCamera } from '@material-ui/icons'
import { authAxios } from '../../../helpers/axios'
import publicFlexSDK from '../../../helpers/publicFlexSDK'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { fetchCurrentUser } from '../../../ducks/user.duck'

interface PropsI extends MapDispatchPropsI {}

interface FormPropsI {
  FYBudget: number | string
  FYUserBudget: number | string
  FYMonth?: number | string
  logoUrl?: string
}

const HRSettingPage: React.FC<PropsI> = ({ fetchCurrentUserA }) => {
  const [logo, setLogo] = useState(null)
  const [initialValues, setInitialValues] = useState<FormPropsI>({
    FYBudget: '',
    FYUserBudget: '',
    FYMonth: 'none',
    logoUrl: '',
  })

  useEffect(() => {
    fetchCompanySettings()
      .then((res) => {
        console.log(res?.data)

        if (res.data) {
          setInitialValues({
            FYBudget: res?.data?.FYBudget,
            FYUserBudget: res?.data?.FYUserBudget,
            FYMonth: res?.data?.FYMonth,
            logoUrl: res?.data?.logoUrl,
          })
          setLogo(res?.data?.logoUrl)
        } else {
          setInitialValues({
            FYBudget: 0,
            FYUserBudget: 0,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const validationSchema = yup.object({
    FYBudget: yup.number().label('Financial year budget').required(),
    FYUserBudget: yup.number().label('Financial year default user budget').required(),
    FYMonth: yup.number().label('Financial year ending month').required(),
    // logoUrl: yup.string(),
  })

  const onSubmitForm = (values: any, formikHelpers: FormikHelpers<FormPropsI>) => {
    saveCompanySettings(values)
      .then(() => {
        formikHelpers.setSubmitting(false)
        setInitialValues(values)
        fetchCurrentUserA()
      })
      .catch(() => {
        formikHelpers.setSubmitting(true)
      })
  }

  return (
    <MainDashboardWrapper title="Settings" page="corp-settings">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formikProps: FormikProps<FormPropsI>) => {
          // console.log(formikProps.values)

          const uploadImage = (event: any) => {
            // console.log(process.env.REACT_APP_PUBLIC_FLEX_CLIENT_ID)

            if (event.target.files && event.target.files[0]) {
              let img = event.target.files[0]
              imageUpload(img).then((res: any) => {
                // console.log({ res })
                const url = res.data.data.secure_url
                setLogo(url)
                formikProps.setFieldValue('logoUrl', url)
              })
              // console.log({ img })
              // uploadImage(img).then((res) => {
              //   console.log({ res })
              // })
            }
          }
          return (
            <Form className={css.form}>
              <FormControl variant={'outlined'} className="w-fit">
                <p className="text-gray-600 font-medium mb-2">Company Logo</p>
                <Button variant="outlined" component="label">
                  {logo ? (
                    <img
                      src={logo}
                      // src="https://sharetribe.imgix.net/5df08725-82ea-4e79-b724-cffcb072998e/63bfef9f-ef79-4961-b9fb-ca297f64aa4f?auto=format&fit=clip&h=750&w=750&s=ec76657a597b38b52884e9bf182c35e6"
                      alt="company logo"
                      className="h-28"
                    />
                  ) : (
                    'Upload'
                  )}

                  <input hidden accept="image/*" multiple type="file" onChange={uploadImage} />
                </Button>
              </FormControl>
              <br />
              <FormControl variant={'outlined'}>
                <InputLabel>FY Ending Month</InputLabel>
                <Select
                  label={'FY Ending Month'}
                  value={formikProps.values.FYMonth}
                  onChange={(e) => formikProps.setFieldValue('FYMonth', e.target.value)}
                >
                  <MenuItem disabled value={'none'}>
                    Not Selected
                  </MenuItem>
                  <MenuItem value={0}>January</MenuItem>
                  <MenuItem value={1}>February</MenuItem>
                  <MenuItem value={2}>March</MenuItem>
                  <MenuItem value={3}>April</MenuItem>
                  <MenuItem value={4}>May</MenuItem>
                  <MenuItem value={5}>June</MenuItem>
                  <MenuItem value={6}>July</MenuItem>
                  <MenuItem value={7}>August</MenuItem>
                  <MenuItem value={8}>September</MenuItem>
                  <MenuItem value={9}>October</MenuItem>
                  <MenuItem value={10}>November</MenuItem>
                  <MenuItem value={11}>December</MenuItem>
                </Select>
                <FormHelperText>
                  The financial budget will be allocated automatically on the selected month above
                </FormHelperText>
              </FormControl>

              <Field
                className={css.input_field}
                component={TextField}
                name="FYBudget"
                variant="outlined"
                label="Budget for Financial Year"
                color="primary"
                required
                InputProps={{
                  inputComponent: NumberFormatInputField,
                }}
              />

              <Field
                className={css.input_field}
                component={TextField}
                name="FYUserBudget"
                variant="outlined"
                label="Default employee budget per Financial Year"
                color="primary"
                required
                InputProps={{
                  inputComponent: NumberFormatInputField,
                }}
              />

              <Button
                className={css.submit_button}
                color="primary"
                variant="contained"
                type="submit"
                disableElevation
                // disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
              >
                {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Save'}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </MainDashboardWrapper>
  )
}

interface MapDispatchPropsI {
  fetchCurrentUserA: () => Promise<ApiResponseI>
}

const mapStateToProps = (state: AppStateI) => {
  return {
    currentUser: state.user.currentUser,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>) => {
  return {
    fetchCurrentUserA: () => dispatch(fetchCurrentUser()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HRSettingPage))
