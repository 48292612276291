import React, { CSSProperties, MouseEventHandler, useState } from 'react'
import css from './TableActions.module.scss'
import '@szhsin/react-menu/dist/index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { Button, CircularProgress, Menu, MenuItem, Tooltip } from '@material-ui/core'

interface ActionI {
  icon: IconProp
  action: any
  tooltip?: string
  isLoading?: boolean
  buttonStyle?: CSSProperties
}

interface PropsI {
  actions: ActionI[]
  isMenu?: boolean
}

const TableActions: React.FC<PropsI> = (props) => {
  const { actions, isMenu } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={css.base}>
      {isMenu ? (
        <div>
          <Button
            style={{
              height: 40,
            }}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {actions.map((item, index) => {
              // console.log('item.action')

              return (
                <MenuItem onClick={item.action} key={index}>
                  <Tooltip key={index} title={item.tooltip || ''}>
                    <div className={css.icon_button} style={item.buttonStyle}>
                      <FontAwesomeIcon className={css.icon} icon={item.icon} />
                    </div>
                  </Tooltip>
                </MenuItem>
              )
            })}
          </Menu>
        </div>
      ) : (
        actions.map((item, index) => {
          return (
            <Tooltip key={index} title={item.tooltip || ''}>
              {item.isLoading ? (
                <CircularProgress />
              ) : (
                <div className={css.icon_button} style={item.buttonStyle} onClick={item.action}>
                  <FontAwesomeIcon className={css.icon} icon={item.icon} />
                </div>
              )}
            </Tooltip>
          )
        })
      )}
    </div>
  )
}

export default TableActions
