import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'

export const inviteUser = async (values: any) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/employee/invite-users`, {
        values,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const inviteBulkUser = async (data: any) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/employee/invite-bulk-users`, {
        data,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchMyInvitations = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get(`${backendUrl}/hr/employee/my-invitations`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const reInviteUser = async (id: number) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/employee/re-invite-user`, {
        id,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
