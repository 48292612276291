import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import css from './EventsView.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {}

const EventsView: React.FC<PropsI> = (props) => {
  const arr = [
    'Mastering Time Management for Success',
    'Unlocking the Power of Emotional Intelligence',
    'Career Development in the Digital Age',
    'Building Resilience in Challenging Times',
  ]
  return (
    <div className="flex flex-col gap-2 pt-5">
      <p className="font-bold mb-2">Upcoming Events</p>
      {arr.map((x, i) => {
        return (
          <div
            key={i}
            className="flex flex-col p-4"
            style={{
              border: '1px solid hsla(180, 100%, 23%, 1)',
            }}
          >
            <p className="text-lg">
              <FontAwesomeIcon size="sm" icon={faCalendar} /> {x}
            </p>
            <p
              className="font-bold"
              style={{
                color: 'hsla(180, 100%, 23%, 1)',
              }}
            >
              22 Dec 2023
            </p>
          </div>
        )
      })}
    </div>
  )
}

interface MapStatePropsI {
  // currentCompany: any
}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    // currentCompany: state.user.currentCompany,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EventsView))
