import { authAxios, authAxiosFile } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'
import axios from 'axios'

export const fetchCompanySettings = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/settings/get-company-settings`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const saveCompanySettings = async (values: any) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/settings/save-company-settings`, {
        values,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchCompanyBudgetRecords = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/settings/company-budget-details`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const allocateCompanyBudget = async (amount: number | string, remark: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/settings/request-company-budget`, {
        amount,
        remark,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const imageUpload = async (file: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const formData = new FormData()
      formData.append('image', file)

      const res = await axios.post(`${backendUrl}/hr/settings/upload-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      resolve(res)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
